import Glide, { Controls, Breakpoints, Swipe, Autoplay, Images } from '@glidejs/glide/dist/glide.modular.esm'
(function () {
    const carouselElements = document.getElementsByClassName('js-mbb-carousel');    
    Array.from(carouselElements).forEach(carousel => {
        const autoPlay = carousel.getAttribute('data-autoplay') === '0' || carousel.getAttribute('data-autoplay') === 'false' ? false : parseInt(carousel.getAttribute('data-autoplay'), 10)
        new Glide(carousel, {
            type: 'carousel',
            gap: 0,
            autoplay: autoPlay,
            animationDuration: 1000
        }).mount({ 
            Controls, 
            Breakpoints, 
            Swipe, 
            Autoplay,
            Images });
    });
})();
